//
import React from "react";
import { Modal } from "./Modal";

import "../scss/Modal.scss";

export function WebDesign({ setModal }) {
  return (
    <div>
      <Modal
        setModal={setModal}
        title='Web Design'
        //content={} prints out litteral
        //content={[]} prints out html
        //must wrap all content in a single parent div
        content={[
          <div>
            <p>
              In general web design pertains to the front-end design of
              websites, the look & feel of your digital content.
            </p>
            <p>
              Web design entails many different skills and disciplines in the
              production of websites. <br />
              Graphic design ( avatar's & logo's ), User Interface design, User
              Experience design.
            </p>
            <p>
              In the Web Design process there are many stages, and all need to
              be accomplished to achieve a successful website.
            </p>
            <p>
              Most common development languages for web design are HTML, CSS and
              JAVASCRIPT. <br />
              In todays market there are Multiple frameworks and technology to
              enhance the UI / UX of your visitor.
              <br />
            </p>
          </div>,
        ]}
      />
    </div>
  );
}
