//
import React from "react";

import "../scss/Copyright.scss";

export const Copyright = () => {
  const year = new Date();
  return (
    <div className='copyright'>
      <div>
        &copy; 2000 - {year.getFullYear()} &nbsp;
        <a className='scorpio' href='https://scorpiocoding.com'>
          ScorpioCoding.com
        </a>
      </div>
    </div>
  );
};
