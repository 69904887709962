//
import React from "react";
import { Modal } from "./Modal";

import "../scss/Modal.scss";

export function WebContent({ setModal }) {
  return (
    <div>
      <Modal
        setModal={setModal}
        title='Web Content'
        //content={} prints out litteral
        //content={[]} prints out html
        //must wrap all content in a single parent div
        content={[
          <div>
            <p>
              The digital content or the story that your web design has to
              reveal.
              <br />
              Writing digital content is not the task of a web designer nor that
              of the client.
            </p>
            <p>
              Web Content is a crucial part of creating a successful website.{" "}
              <br />
              Poor digital web content can lead to disappointing end result
            </p>
            <p>
              Web Content Writer can be asked to create your digital content and
              together with the web design create a stunning success.
            </p>
          </div>,
        ]}
      />
    </div>
  );
}
