//
import { Icons } from "./components/Icons";
import { Copyright } from "./components/Copyright";

import { Home } from "./pages";

import "./scss/App.scss";

function App() {
  return (
    <div className='App'>
      <Home />
      <Icons />
      <Copyright />
    </div>
  );
}

export default App;
