//
import React from "react";
import { Modal } from "./Modal";

import "../scss/Modal.scss";

export function WebDevelopment({ setModal }) {
  return (
    <div>
      <Modal
        setModal={setModal}
        title='Web Development'
        //content={} prints out litteral
        //content={[]} prints out html
        //must wrap all content in a single parent div
        content={[
          <div>
            <p>
              Web Development pertains to the development of your website.
              <br />
              For Development we here @ ScorpioCoding mainly use React and
              QuickStart.
            </p>
            <p>
              QuickStart is a homegrown lightweight customizable Dockerized
              Php-Mvc framework.
              <br />
              <br />
              <a
                href='https://github.com/scorpiocoding'
                target='_blank'
                rel='noopener noreferrer'
              >
                Quickstart Versions on Github
              </a>
            </p>
          </div>,
        ]}
      />
    </div>
  );
}
