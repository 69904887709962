//
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faGithub,
  faBehance,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import "../scss/Icons.scss";

export function Icons() {
  return (
    <div className='media'>
      <a
        href='https://codesandbox.io/u/scorpiocoding'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faSquare} />
      </a>
      <a
        href='https://github.com/ScorpioCoding'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        href='https://www.behance.net/scorpiocoding'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faBehance} />
      </a>
      <a
        href='https://www.facebook.com/ScorpioCoding/'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href='mailto:dev@scorpiocoding.com?subject=contact from webserver'>
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </div>
  );
}
