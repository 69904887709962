//
import React from "react";
import { Modal } from "./Modal";

export function WebOps({ setModal }) {
  return (
    <div>
      <Modal
        setModal={setModal}
        title='Web Ops'
        //content={} prints out litteral
        //content={[]} prints out html
        //must wrap all content in a single parent div
        content={[
          <div>
            <p>
              Web Ops is basically everything to do with the webserver.
              <br />
              The Creation & Maintenance of the webserver (VPS),Operating
              software, Docker (compose), Web Hosting, Domain Names, etc.
            </p>
          </div>,
        ]}
      />
    </div>
  );
}
