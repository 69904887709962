//
import React, { useState } from "react";

import { WebDesign } from "../components/WebDesign";
import { WebContent } from "../components/WebContent";
import { WebDevelopment } from "../components/WebDevelopment";
import { WebOps } from "../components/WebOps";

import banner from "../img/scorpiocoding-banner.png";
import "../scss/Home.scss";

export function Home() {
  const [isWebDesign, setDesign] = useState(false);
  const [isWebContent, setContent] = useState(false);
  const [isWebDevelopment, setDev] = useState(false);
  const [isWebOps, setOps] = useState(false);

  return (
    <main className='home'>
      {isWebDesign && <WebDesign setModal={setDesign} />}
      {isWebContent && <WebContent setModal={setContent} />}
      {isWebDevelopment && <WebDevelopment setModal={setDev} />}
      {isWebOps && <WebOps setModal={setOps} />}

      <div className='container'>
        <div>
          <img src={banner} alt='ScorpioCoding Banner Image' />
        </div>
      </div>
      <div className='container'>
        <div className='slogan_wrapper'>
          <h1 className='slogan__001'>DESIGN & DEVELOPMENT</h1>
          <h2 className='slogan__002'>WEB - LOGO - AVATAR</h2>
        </div>
      </div>

      <div className='grid-columns-3'>
        <article
          onClick={() => {
            setDesign(true);
          }}
        >
          <h2>Web Design</h2>
        </article>

        {/* <article
          onClick={() => {
            setContent(true);
          }}
        >
          <h3>Web Content</h3>
        </article> */}
        <article
          onClick={() => {
            setDev(true);
          }}
        >
          <h2>Web Development</h2>
        </article>
        <article
          onClick={() => {
            setOps(true);
          }}
        >
          <h2>Web Ops</h2>
        </article>
      </div>
    </main>
  );
}
